import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CustomerModel } from 'src/models/mongo/customer.model';
import { DealModel } from 'src/models/mongo/deal.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'opporunity-deal-phase',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './opporunity-deal-phase.component.html',
  styleUrls: ['./opporunity-deal-phase.component.scss'],
})
export class OpporunityDealPhaseComponent implements OnInit {
  @HostBinding('class') class = 'h-100';
  @Input() dealPhaseData: DealModel;
  isCreatedDealCollapsed: boolean = true;
  isManagerDealCollapsed: boolean = true;
  isGPMDealCollapsed: boolean = true;
  isDealGoogleRegisteredCollapsed: boolean = true;
  isDealGoogleApprovedCollapsed: boolean = true;
  @Input() isGWS: boolean = true;
  @Input() domain;
  @ViewChild('dealForm') public dealForm: ModalDirective;
  addDeal: boolean = true;
  @Output() backToMainOpp = new EventEmitter<DealModel>();
  @Output() startDeal = new EventEmitter<any>();
  currentUser: UserModel;
  currentCustomer: CustomerModel;
  show: boolean;
  loginUsersData;

  constructor(
    private localStorage: LocalStorageService,
    private changeDetector: ChangeDetectorRef,
    private http: HttpClient
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    this.loginUsersData = Utilities.getLoginUserInfo(localStorage);
    var cstjson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    if (!Utilities.isNullOrEmpty(cstjson))
      this.currentCustomer = JSON.parse(cstjson);
  }

  ngOnInit(): void {
    if (!Utilities.isNullOrEmpty(this.dealPhaseData.id)) this.addDeal = false;
  }

  timeSince(createdAt: Date): string {
    const createdDate = createdAt instanceof Date ? createdAt : new Date(createdAt);
    const now = new Date();

    if (isNaN(createdDate.getTime())) {
      throw new Error('Invalid date provided');
    }

    const diffInMilliseconds = now.getTime() - createdDate.getTime();
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInDays > 365) {
      const years = Math.floor(diffInDays / 365);
      return `${years} year${years > 1 ? 's' : ''} ago`;
    } else if (diffInDays > 30) {
      const months = Math.floor(diffInDays / 30);
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else if (diffInDays > 0) {
      return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
    } else if (diffInHours > 0) {
      return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
    } else if (diffInMinutes > 0) {
      return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
    } else {
      return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago`;
    }
  }

  createDeal() {
    if (
      Utilities.isNullOrEmpty(this.localStorage.get(Unicode.CURRENT_CUSTOMER))
    ) {
      alert('Select a Customer');
      return;
    }
    this.startDeal.emit();
    this.addDeal = true;
    this.dealForm.show();
    this.show = true;
  }

  saveDeal(model) {
    this.dealForm.hide();
    this.show = false;
    this.addDeal = false;
    this.backToMainOpp.emit(model);
  }

  editDeal() {
    this.addDeal = false;
    this.show = true;
    this.dealForm.show();
  }

  sendForManagerApproval() {
    var receivers = [
      !Utilities.isNullOrEmpty(this.currentUser.manager_id)
        ? this.currentUser.manager_email
        : this.loginUsersData?.gpm_group_email,
    ];
    var body = {
      receivers: receivers,
      deal_rfid: this.dealPhaseData.id,
    };
    this.http
      .put(Endpoint.API_URL + Endpoint.SEND_DEAL_FOR_APPROVAL, body)
      .toPromise()
      .then((resp: any) => {
        if (resp?.id) {
          this.dealPhaseData.draft = false;
          alert('Sent');
        } else {
          alert(resp.message);
        }
      });
  }

  reviewDeal() {
    this.show = true;
    this.changeDetector.detectChanges();
    console.log(this.dealPhaseData);
    this.dealForm.show();
  }
}
